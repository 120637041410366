img {
    border-radius: 10%;
    border: 1px solid black;
}

.DataBoxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 2vh;
    position: relative;
}

.CarouselImg {
    border-radius: 50%;
    border: 1px solid black;
    margin-bottom: 4;
}

.CarouselRow {
    display: flex;
    justify-content: center;
}

.carousel-inner {
    height: 500px;
}

.carousel-item {
    height: 500px;
}
