* {
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Montserrat', sans-serif;
}

.all_unset {
    all: unset;
}

.intro {
    background-color: #4a4a4a;
    background-image: url('./assets/IntroBackground.jpg');
    background-size: cover;
    height: 100vh;
}

.skills {
    background-color: #6d6d6d;
}

.contact {
    background-color: #242325;
}

.work {
    background-image: url('./assets/workBackground.png');
}

.work {
    background-image: url('./assets/workBackground.png');
}

.intro .titleHeading {
    color: white;
}

.intro .descriptionHeading {
    color: white;
}

.intro .typedText {
    color: #242325;
}

.css-144eq6x-MuiPaper-root-MuiAppBar-root {
    box-shadow: none;
}